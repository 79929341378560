import React, { useLayoutEffect, useRef } from "react";
import logo from "../logo.svg";
import Logo from "./Logo";
import useScrollDown from "../hooks/useScrollDown";
import GlassContainer, { BLURS } from "./GlassContainer";
import SmoothScrollComponent from "./SmoothScrollComponent";
import { useMediaQuery } from "../hooks/useMediaQuery";
import gsap from "gsap";

export default function NavBar() {
  const didScrollDown = useScrollDown({ offset: 50 });
  const didScrollWayyyDown = useScrollDown({ offset: 500 });
  const isMobile = useMediaQuery("(max-width: 768px)");

  const content = (
    <div className="px-3 lg:px-6 py-2 lg:py-4 flex justify-between items-center">
      <Logo linkTo={"/"} />
      <SmoothScrollComponent
        className=""
        href={`contact-section-${isMobile ? "mobile" : "desktop"}`}
        offset={isMobile ? 300 : 150}
      >
        <ContactLink show={didScrollWayyyDown} />
      </SmoothScrollComponent>
    </div>
  );

  return (
    <header>
      <nav
        className={`w-screen  transition-all fixed z-50 rounded-b-md  ${
          didScrollDown ? "shadow-xl" : ""
        }`}
      >
        {didScrollDown ? (
          <GlassContainer className="rounded-md">{content}</GlassContainer>
        ) : (
          content
        )}
      </nav>
    </header>
  );
}

const ContactLink = ({ show }) => {
  const container = useRef(null);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (show) {
        gsap.to(container.current, {
          autoAlpha: 1,
          duration: 0.15,
          scale: 1,
        });
      }
    }, container);
    return () => ctx.revert();
  }, [show]);

  return (
    <div
      ref={container}
      style={{ opacity: 0, visibility: "hidden", scale: 0 }}
      className="border font-bold border-gray-600  hover:bg-secondary/50 transition-colors duration-300 rounded-full py-1 px-3"
    >
      Contact
    </div>
  );
};
