import React, { useLayoutEffect, useRef } from "react";
import linkdinIcon from "../assets/linkedin-icon.svg";
import envelopIcon from "../assets/open-envelop.svg";
import cahtIcon from "../assets/chat-bubble.svg";
import { gsap } from "gsap";
import { useMediaQuery } from "../hooks/useMediaQuery";

export default function Contact({ isMobile }) {
  const links = [
    {
      // type: "mail",
      iconSrc: envelopIcon,
      iconAlt: "mail",
      href: "mailto:idan.schiller@gmail.com",
      external: true,
    },
    {
      iconSrc: cahtIcon,
      iconAlt: "whatsApp Icon",
      href: "https://wa.me/972546983157",
      external: true,
    },
    {
      iconSrc: linkdinIcon,
      iconAlt: "linkedin Icon",
      href: "https://www.linkedin.com/in/idan-schiller-0138481b6/",
      external: true,
    },
  ];

  const container = useRef(null);
  const isMobileDevice = useMediaQuery("(max-width: 768px)");
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      // if (isMobileDevice) {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: container.current,
            start: "top center",
          },
        })
        .to("#contact-section-slogan-underline", {
          autoAlpha: 1,
          width: "100%",
        });
      // }
      // else {
      //   gsap.to("#contact-section-slogan-underline", {
      //     autoAlpha: 1,
      //     width: "100%",
      //     delay: 3,
      //   });
      // }
    }, container);

    return () => ctx.revert();
  }, [isMobileDevice]);
  return (
    <div
      ref={container}
      id={`contact-section-${isMobile ? "mobile" : "desktop"}`}
      className="flex flex-col text-center lg:text-start items-center lg:items-start space-y-8 lg:space-y-4 py-14 lg:py-0"
    >
      <h2 className="text-xl">
        Have an idea you want to talk about?{" "}
        <div className="font-bold inline-block" id="contact-section-slogan">
          drop me a line:
          <div
            className="w-0 border-t-2 border-gray-900"
            id="contact-section-slogan-underline"
          />
        </div>
      </h2>
      <div
        id="contact-icons"
        className="flex flex-row items-center space-x-2 lg:space-x-6 justify-around w-2/3 lg:w-full lg:justify-start"
      >
        {links.map((link) => (
          <IconLink key={link.href} {...link} />
        ))}
      </div>
    </div>
  );
}

export const IconLink = ({ iconSrc, iconAlt, href, external, type }) => {
  return (
    <a
      href={href}
      target={external ? "_blank" : "_self"}
      rel={external ? "noopener noreferrer" : ""}
      className="w-10 h-10 hover:opacity-80 transition-all hover:scale-110 duration-300"
    >
      <img src={iconSrc} alt={iconAlt} className="w-10 h-10" />
    </a>
  );
};
