import React from "react";

export const BLURS = Object.freeze({
  none: "none",
  xs: "xs",
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl",
  "2xl": "2xl",
});

export default function GlassContainer({
  children,
  className = "p-8 rounded-xl",
  blur = 5.9,
  ...props
}) {
  return (
    <div
      className={`${className} transition-all bg-gray-100 bg-opacity-10 bg-clip-padding backdrop-blur-${blur} backdrop-filter`}
      style={{
        background: "rgba(255, 255, 255, 0.19)",
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
        backdropFilter: `blur(${blur}px)`,
        WebkitBackdropFilter: "blur(5.9px)",
      }}
      {...props}
    >
      {children}
    </div>
  );
}
