import { ArrowRightIcon } from "@heroicons/react/outline";
import React from "react";
import "./ProjectsList.css";

const dummy = [
    {
        title: "Groceries List WhatsApp App",
        description: "",
        link: "https://groceroo.app/",
        image: "",
        company: "Groceroo",
    },
    {
        title: "Corporate Website",
        description: "",
        link: "https://www.redefine.net/",
        image: "",
        company: "Redefine",
    },
    {
        title: "Comperhensive Research System",
        description: "",
        link: "https://thinking-better-math.web.app/",
        image: "",
        company: "Haifa University",
    },
    {
        title: "Corporate Website",
        description: "",
        link: "https://www.otterize.com/",
        image: "",
        company: "Otterize",
    },

    {
        title: "Crypto Web Application",
        description: "",
        link: "https://app.revault.network/vaults",
        image: "",
        company: "Revault",
    },
    {
        title: "Marketing Website",
        description: "",
        link: "https://dne-realestate.com/",
        image: "",
        company: "DNE Real Estate",
    },
    {
        title: "Animated Corporate Website",
        description: "",
        link: "https://blockaid.io/",
        image: "",
        company: "Blockaid",
    },
    {
        title: "Shopify App",
        description: "",
        link: "",
        image: "",
        company: "Enpitech",
    },
    {
        title: "Marketing Website",
        description: "",
        link: "https://ganyeledteva.co.il/",
        image: "",
        company: "Gan Yaledey Hateva",
    },

    {
        title: "Web Application",
        description: "",
        link: "https://vanywhere.com/hue_jackson",
        image: "",
        company: "Vanywhere",
    },
    {
        title: "Web Application",
        description: "",
        link: "https://app.moneytor.co.il",
        image: "",
        company: "Moneytor",
    },
    {
        title: "E-Commerce Site for Sport Matches Tickets",
        description: "",
        link: "https://ticketguy.co.il",
        image: "",
        company: "Ticket Guy",
    },
];

export default function ProjectsList({ projects = dummy }) {
    return (
        <table className="w-full relative flex flex-col px-2 lg:px-4 rounded-md overflow-y-scroll">
            <thead className="w-full sticky bg-[#FAFAFA] rounded-t-md z-20 inset-0 shadow-sm font-semibold font-roslin text-gray-500">
                <tr className="flex w-full text-start items-center py-3 lg:py-2 px-2 lg:px-1 rounded-md">
                    <th className="text-lg font-bold w-3/5 lg:w-2/3 text-start">
                        Project
                    </th>
                    <th className="w-1/4 text-start">Company</th>
                    <th className="text-start"></th>
                </tr>
            </thead>
            <tbody className="w-full ">
                {projects.map((project, index) => {
                    const isLast = index === projects.length - 1;
                    return project.link ? (
                        <a
                            href={project.link}
                            key={project.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <ProjectRowItem {...project} isLast={isLast} />
                        </a>
                    ) : (
                        <ProjectRowItem {...project} isLast={isLast} disabled />
                    );
                })}
            </tbody>
        </table>
    );
}

const ProjectRowItem = ({ title, company, isLast, disabled }) => (
    <tr
        className={`flex w-full items-center py-3 lg:py-2 group px-2 lg:px-1 border-y lg:hover:scale-[101%] transition-all  ${
            isLast ? "rounded-b-md" : ""
        }`}
        key={title}
    >
        <td className="w-3/5 lg:w-2/3">
            <h3 className="text-base lg:text-lg hover:font-semibold font-roslin">
                {title}
            </h3>
        </td>
        <td className="w-1/4">
            <p className="">{company}</p>
        </td>
        <td className="ml-auto">
            <ArrowRightIcon
                className={`w-5 h-5 ${
                    disabled
                        ? "text-gray-400"
                        : "group-hover:scale-110 group-hover:translate-x-1 transition-all duration-150 group-hover:text-blue-400"
                }`}
            />
        </td>
    </tr>
);
