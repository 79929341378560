import { useState, useEffect, useCallback } from "react";

const useScrollDown = ({ offset }) => {
  const [scrolledDown, setScrolledDown] = useState(false);

  const handleScroll = useCallback(() => {
    const scrolled = window.scrollY > offset;
    setScrolledDown(scrolled);
  }, [offset]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return scrolledDown;
};

export default useScrollDown;
