import React from "react";
import logo from "../assets/favicon png-01.png";
// import logo from "../assets/favicon jpg-02.jpg";

export default function Logo({ linkTo }) {
  const logoElement = (
    <img
      src={logo}
      alt="Idan Schiller Logo"
      className="w-6 h-6 lg:w-8 lg:h-8"
    />
  );

  return linkTo ? <a href={linkTo}>{logoElement}</a> : logoElement;
}
