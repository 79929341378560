import { gsap } from "gsap";
import { useEffect } from "react";

export function useMouseParallax({
  element,
  movementSensitivity = 25,
  disabled,
}) {
  useEffect(() => {
    if (disabled) return;
    const handleMouseMove = (event) => {
      const mouseX = event.clientX;
      const mouseY = event.clientY;

      const percentX = mouseX / window.innerWidth;
      const percentY = mouseY / window.innerHeight;

      gsap.to(element, {
        x: (percentX - 0.5) * movementSensitivity,
        y: (percentY - 0.5) * movementSensitivity,

        ease: "power2.out",
      });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [disabled, element, movementSensitivity]);
}
