import React from "react";

const SmoothScrollComponent = ({
  href,
  offset = 0,
  className = "",
  children,
}) => {
  const handleClick = () => {
    const element = document.getElementById(href);

    if (element) {
      const topOffset = element.offsetTop - offset;
      window.scrollTo({
        top: topOffset,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className={`${className} cursor-pointer`} onClick={handleClick}>
      {children}
    </div>
  );
};

export default SmoothScrollComponent;
