import NavBar from "./components/NavBar";
import Projects from "./components/Projects";
import Hero from "./components/Hero";
import Footer from "./components/Footer";

function App() {
  return (
    <Layout>
      <Hero />
      <Projects />
    </Layout>
  );
}

export default App;

const Layout = ({ children }) => {
  return (
    <div className=" font-aeonik min-h-screen min-w-screen">
      <NavBar />
      <div className="">
        <main className="">{children}</main>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export const Section = ({ className = "", children, forwardRef, style }) => {
  return (
    <div
      style={style}
      ref={forwardRef}
      className={`${className} w-screen bg-white text-gray-900 min-h-screen px-8 pt-10 lg:pt-14 lg:px-20 pb-3 lg:pb-5 xl:px-32 2xl:px-40`}
    >
      {children}
    </div>
  );
};
