import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Contact from "./Contact";
import selfImg from "../assets/JNR02136-squared.JPG";
import bgImg from "../assets/bg7.jpg";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Section } from "../App";
import { useMouseParallax } from "../hooks/useMouseParallax";
import { useMediaQuery } from "../hooks/useMediaQuery";

const backgroundGradients = [
  "conic-gradient(from 119deg at -19% 36%, transparent 50%, #bfe2f8 75%, transparent 93%)",
  "conic-gradient(from 119deg at -19% 36%, transparent 50%, red 75%, transparent 93%)",
  "conic-gradient(from 119deg at -19% 36%, transparent 50%, green 75%, transparent 93%)",
];

gsap.registerPlugin(ScrollTrigger);

export default function Hero() {
  const container = useRef(null);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [gradientIndex, setGradientIndex] = useState(0);

  // useLayoutEffect(() => {
  //   let ctx = gsap.context(() => {
  //     gsap.to(container.current, {
  //       background:
  //         "conic-gradient(from 130deg at -108% -170%, transparent 45%, #527436 89%, transparent 100%)",
  //       duration: 5,
  //     });
  //   }, container);

  //   return () => ctx.revert();
  // }, [gradientIndex]);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      let h1 = document.querySelector("h1");

      const title = h1.textContent;

      const wrappedHeading = wrapCharactersAndWords(title);
      h1.removeChild(h1.firstChild);
      h1.appendChild(wrappedHeading);

      gsap
        .timeline()
        // .from(container.current, {
        //   clipPath: "circle(0% at 50% 50%)",
        //   delay: 0.2,
        //   duration: 1.5,
        // })
        .from(
          ".hero-word",
          {
            autoAlpha: 0,
            y: 50,
            stagger: 0.05,
            delay: 0.2,
            duration: 0.8,
            // skewY: 3,
          },
          "<"
        )
        .from(
          ".hero-description-row",
          {
            autoAlpha: 0,
            y: 20,
            stagger: 0.12,
            delay: 0.3,
            duration: 0.5,
            // skewY: 2,
          },
          ""
        )
        .from(
          [
            // "#hero-description",
            "#contact-section-mobile",
            "#contact-section-desktop",
            "#hero-img",
          ],
          {
            autoAlpha: 0,
            y: 100,
            delay: 0.5,
            duration: 0.7,
            ease: "power2.out",
            // skewY: 3,
          },
          "<+=50%"
        );
      // .from(
      //   "#hero-img",
      //   {
      //     clipPath: "inset(0 100% 0 0)",
      //     autoAlpha: 0,
      //     duration: 0.9,
      //   },
      //   "<"
      // );

      if (isMobile) {
        gsap
          .timeline({
            scrollTrigger: {
              trigger: container.current,
              start: "top top",
              end: "bottom top+=80%",
              scrub: 1,
            },
          })

          .to("#hero-img", {
            yPercent: 12,
            scale: 0.45,
          })
          .to(
            "#hero-text-content",
            {
              y: -100,
              autoAlpha: 0,
            },
            "<"
          )
          .to(
            "#contact-section-mobile",
            {
              y: -100,
              scale: 1.2,
            },
            "<"
          )
          .to(
            container.current,
            {
              backgroundImage: "white",
            },
            "<"
          );
        return;
      }

      gsap
        .timeline({
          scrollTrigger: {
            trigger: container.current,
            start: "top top",
            end: "bottom top+=50%",
            scrub: 1,
          },
        })
        .to("#hero-img", {
          // yPercent: -60,
          // xPercent: 50,
          autoAlpha: 0.4,
          yPercent: -100,
        })
        .to(
          "#hero-text-content",
          {
            // y: -100,
            xPercent: -50,
            autoAlpha: 0,
          },
          "<"
        )
        .to(
          "#contact-section-desktop",
          {
            xPercent: 50,
            scale: 1.2,
          },
          "<"
        )
        .to(
          "#contact-icons",
          {
            xPercent: 33,
          },
          "<"
        )
        .to(
          container.current,
          {
            backgroundImage: "white",
          },
          "<"
        );
    }, container);

    return () => ctx.revert();
  }, [isMobile]);
  const [imgSrc, setImgSrc] = useState(selfImg);

  // useMouseParallax({ element: "#hero-img", movementSensitivity: 50 });

  return (
    <Section
      forwardRef={container}
      style={{
        // clipPath: "circle(80% at 50% 50%)",
        // background:
        // "conic-gradient(from 126deg at -128% -180%, transparent 15%, #367496 89%, transparent 100%)",
        backgroundImage: `conic-gradient(from 119deg at -19% 36%, transparent 50%, #bfe2f8 ${
          isMobile ? "25%" : "75%"
        }, transparent 93%)`,
      }}
      className="flex items-center justify-center"
    >
      <div className="flex flex-col space-y-10 lg:space-y-20 justify-between pt-10 lg:pt-0">
        <div
          id="hero-content"
          className="flex flex-col lg:flex-row justify-center items-center space-y-10 lg:space-x-28"
        >
          <div className="flex flex-col items-center space-y-10 w-full lg:w-1/2 ">
            <div
              id="hero-text-content"
              className="flex flex-col items-center space-y-10 w-full"
            >
              <div className="overflow-hidden w-full">
                <h1
                  id="hero-text"
                  // style={{
                  //   clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
                  // }}
                  className="text-3xl lg:text-6xl font-semibold font-roslin text-start w-full"
                >
                  Hi, I'm Idan Schiller
                </h1>
              </div>
              <p className="text-lg w-full" id="hero-description">
                <div className="hero-description-row">
                  I specialize in{" "}
                  <span className="font-bold">
                    elevating businesses to their full potential
                  </span>
                  .
                </div>
                <div className="hero-description-row underline">
                  This is achieved through:
                </div>
                <div className="hero-description-row flex gap-2">
                  <span className="hiddn md:block">&#x2022;</span>
                  Crafting <span className="hidden md:inline">
                    visually
                  </span>{" "}
                  stunning websites 🕺
                </div>

                <div className="hero-description-row flex gap-2">
                  <span className="hiddn md:block">&#x2022;</span>
                  Developing tailored software solutions 🚀
                </div>

                <div className="hero-description-row flex gap-2">
                  <span className="hiddn md:block">&#x2022;</span>
                  Harnessing the power of AI tools 🤖
                </div>
                <br />
                <div className="hero-description-row">
                  I'm a committed full-stack software developer, devoted to
                  crafting influential digital solutions.
                </div>

                {/* <div className="hero-description-row">
                  I do it by creating slick-looking websites 🕺
                </div>
                <div className="hero-description-row">
                  by building custom software 🚀
                </div>
                <div className="hero-description-row">
                  and by integrating AI tools 🤖
                </div>
                <div className="hero-description-row">
                  A passionate full-stack software developer, creating digital
                  products that matter.
                </div> */}
              </p>
            </div>
            <div className="hidden lg:block w-full">
              <Contact />
            </div>
          </div>
          <img
            // ref={elementRef}
            id="hero-img"
            style={{
              clipPath: "inset(0 0 0 0)",
              // clipPath: "polygon(0 0, 0 0, 0 0, 0 0)",
            }}
            src={imgSrc}
            // onMouseEnter={() => setImgSrc(selfSmileImg)}
            // onMouseLeave={() => setImgSrc(selfImg)}
            className="w-5/6 md:w-2/3 lg:w-[40%] max-w-[700px] mx-auto h-full lg:h-1/2 rounded-[50%] shadow-md"
            alt="Idan Schiller"
          />
        </div>
        <div className="lg:hidden">
          <Contact isMobile />
        </div>
      </div>
    </Section>
  );
}

function wrapCharactersAndWords(sentence) {
  const divElement = document.createElement("div");
  const words = sentence.split(" ");

  words.forEach((word, index) => {
    const isLastWord = index === words.length - 1;
    const wordElement = document.createElement("div");
    const characters = word.split("");

    characters.forEach((char) => {
      const charElement = document.createElement("span");
      charElement.textContent = char;
      charElement.classList.add("hero-char");
      charElement.style.display = "inline-block";
      wordElement.appendChild(charElement);
      wordElement.style.display = "inline-block";
      wordElement.classList.add("hero-word");
    });
    if (!isLastWord) {
      const spaceElement = document.createElement("div");
      spaceElement.textContent = " ";
      spaceElement.style.width = "0.5rem";
      spaceElement.style.display = "inline-block";
      wordElement.appendChild(spaceElement);
    }

    divElement.appendChild(wordElement);
  });
  // divElement.style.display = ";

  return divElement;
}

/**
 * COPY:
 * "Hello, I'm Idan Schiller,
Empowering businesses to excel.
I achieve this through:
✨ Crafting captivating websites.
🚀 Developing bespoke software solutions.
🤖 Harnessing AI for optimal outcomes.
A dedicated full-stack software developer, crafting impactful digital solutions." 

"Hello, I'm Idan Schiller.
I specialize in elevating businesses.
I achieve this through:

Crafting sleek, eye-catching websites 🕺
Developing tailored software solutions 🚀
Seamlessly integrating AI technologies 🤖
I'm a dedicated full-stack software developer, committed to building impactful digital products."


"Hello, I'm Idan Schiller,
I specialize in empowering businesses to excel.
I achieve this through:

Crafting visually stunning websites 🕺
Developing tailored software solutions 🚀
Harnessing the power of AI tools 🤖
As a dedicated full-stack software developer, I craft impactful digital products."

"Hello, I'm Idan Schiller,
Dedicated to enhancing businesses' success,
I achieve this through:

✨ Crafting polished websites that impress 🕺
🚀 Developing tailored software solutions 🚀
🤖 Harnessing the power of AI for intelligent integration 🤖

As a passionate full-stack software developer,
I create meaningful digital products that drive progress."

"Hello, I'm Idan Schiller,
Empowering Businesses to Thrive.

My mission is to elevate businesses by:

🌐 Crafting Stunning Websites
🚀 Building Tailored Software Solutions
🤖 Harnessing AI Tools

As a dedicated full-stack software developer, I'm committed to shaping digital products that make a difference."


"Hello, I'm Idan Schiller.
I specialize in elevating businesses to their full potential.
I achieve this by crafting visually stunning websites 🕺
developing tailor-made software solutions 🚀
and harnessing the power of AI tools 🤖
I'm a dedicated full-stack software developer, dedicated to building meaningful digital products."


"Hello, I'm Idan Schiller,
Empowering Businesses to Excel 💼

I achieve this by crafting visually stunning websites 🌐,
developing tailor-made software solutions 🚀,
and seamlessly integrating cutting-edge AI technology 🤖.

As a dedicated full-stack software developer, I specialize in crafting impactful digital products."


"Hello, I'm Idan Schiller,
I empower businesses to excel.
I achieve this through:
🌐 Crafting captivating websites
🛠️ Developing tailor-made software
🤖 Harnessing the power of AI
As a dedicated full-stack software developer, I craft digital solutions that make an impact."


"Hello, I'm Idan Schiller.
I specialize in elevating businesses to their full potential.
This is achieved through:

Crafting visually stunning websites 🕺
Developing tailored software solutions 🚀
Harnessing the power of AI tools 🤖
I'm a dedicated full-stack software developer, dedicated to shaping impactful digital products."

 */
